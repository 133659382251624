import React, { Fragment } from "react"
import { Outlet, useLocation, useParams } from "react-router-dom"

export default function App(props: any) {
    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}
