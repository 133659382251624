import React, { useEffect, useRef, useState } from "react"
import axios from "axios"

const apiBase = process.env.REACT_APP_API_BASE

export default function useApiRequest(
    url: string,
    sendData: object,
    responseHandler?: (response: any) => void,
    errorHandler?: (response: any) => void
) {
    const [apiRequestInProgress, setApiRequestInProgress] = useState(false)

    useEffect(() => {
        setApiRequestInProgress(true)
        axios
            .post(url, sendData, {
                baseURL: apiBase,
            })
            .then(
                (response) => {
                    console.log("RESPONSE")
                    console.log(response)
                    if (responseHandler) {
                        responseHandler(response.data)
                    }
                },
                (error) => {
                    console.log("ERROR")
                    console.log(error)
                    if (errorHandler) {
                        errorHandler(error)
                    }
                }
            )
            .finally(() => {
                setApiRequestInProgress(false)
            })
    })
}
