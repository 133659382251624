import React from "react"
import { useCookies } from "react-cookie"

export default function useCookie(setValue?: string): string | null {
    const [cookies, setCookie, removeCookie] = useCookies(["c_cu_s22"])

    if (!setValue) {
        return cookies.c_cu_s22 || null
    }

    const cookieExpires = new Date()
    cookieExpires.setDate(cookieExpires.getDate() + 365)
    let cookieDomain = process.env.REACT_APP_COOKIE_BASE_DOMAIN
    if (!cookieDomain) {
        cookieDomain = "localhost"
    }
    setCookie("c_cu_s22", setValue, {
        path: "/",
        expires: cookieExpires,
        domain: cookieDomain,
    })
    return null
}
