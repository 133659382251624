import React from "react"
import imageLogoWhite from "../images/logo-white.png"
import imageLogoPantone from "../images/logo-pantone.png"
import imageCurtin from "../images/curtin-logo.png"

export default function ErrorResult(props: {
    errorTitle?: string
    errorMessage?: string
}) {
    return (
        <div className="theme-brand min-h-screen">
            <header className="bg-page-header from-primary flex flex-col">
                <div className="flex flex-row justify-center items-center pt-8" style={{ WebkitAlignItems: 'center' }}>
                    <img src={imageLogoWhite} className="w-12" alt="Cercle logo" width="48"
                        height="51" />
                </div>
                <div className="flex flex-row justify-center my-12">
                    <h1 className="text-4xl font-extrabold">
                        Uh oh...
                    </h1>
                </div>
            </header>
            <div className="p-4 flex flex-col w-full justify-center">
                <div className="flex flex-row justify-center my-6 font-bold">
                    {props.errorTitle || "An error occurred."}
                </div>
                <div className="flex flex-row justify-center my-6">
                    {props.errorMessage ||
                        "Please try that again. Contact us for support if it keeps happening."}
                </div>
                <div className="flex flex-row justify-center mt-12">
                    <img src={imageCurtin} alt="Curtin University" />
                </div>
            </div>
        </div>
    )
}
