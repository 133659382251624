import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { createRoot } from "react-dom/client"
import App from "./App"
import { CookiesProvider } from "react-cookie"
import Coffee from "./components/Coffee"
import Survey from "./components/Survey"

const container = document.getElementById("root")
if (container) {
    const root = createRoot(container)
    // @todo: refactor into App.tsx
    root.render(
        <React.StrictMode>
            <CookiesProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<App />}>
                            <Route path="*" element={<Coffee />}/>
                            <Route path="survey">
                                <Route path="*" element={<Survey />} />
                            </Route>
                            <Route path="coffee">
                                <Route path="*" element={<Coffee />} />
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </CookiesProvider>
        </React.StrictMode>
    )
}
