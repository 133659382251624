import React, { useEffect, useState } from "react"
import imageLogoWhite from "../images/logo-white.png"
import imageLogoPantone from "../images/logo-pantone.png"
import imageCurtin from "../images/curtin-logo.png"
import "../styles/App.css"

export default function ResultPage(props) {
    const defaultPageData = {
        title: "Please wait...",
        icon: imageLogoPantone,
        text: "Loading...",
        code: "",
        qr: "",
    }
    const [pageData, setPageData] = useState({
        ...defaultPageData,
    })

    useEffect(() => {
        const newPageData = {
            ...defaultPageData,
        }
        for (const [key, value] of Object.entries(props.pageData)) {
            if (value && value.length > 0) {
                newPageData[key] = value
            }
        }
        setPageData(newPageData)
    }, [props])

    return (
        <div className="theme-brand min-h-screen">
            <header className="bg-page-header from-primary flex flex-col">
                <div className="flex flex-row justify-center items-center pt-8" style={{ WebkitAlignItems: 'center' }}>
                    <img src={imageLogoWhite} className="w-12" alt="Cercle logo" width="48"
                        height="51" />
                </div>
                <div className="flex flex-row justify-center my-12">
                    <h1 className={"text-4xl font-extrabold"}>
                        {pageData.title.length ? pageData.title : "Cercle"}
                    </h1>
                </div>
            </header>
            <div className={"flex flex-row justify-center my-6"}>
                {pageData.icon && (
                    <img src={pageData.icon} alt="Icon" width={64} />
                )}
            </div>
            <div className="flex flex-row justify-center my-6 font-bold">
                {pageData.text ? pageData.text : ""}
            </div>
            {pageData.code && (
                <div className="flex flex-row justify-center my-6">
                    <div
                        className={
                            "border-2 border-teal-300 rounded-xl p-4 select-all text-3xl uppercase " +
                            (pageData.codeBg
                                ? pageData.codeBg
                                : "bg-gray-300")
                        }
                    >
                        {pageData.code}
                    </div>
                </div>
            )}
            {pageData.qr && (
                <div className="flex flex-row justify-center my-6">
                    <img src={pageData.qr} width={150} alt="Scan Me" />
                </div>
            )}
            <div className="flex flex-row justify-center mt-12">
                <img src={imageCurtin} alt="Curtin University" />
            </div>
        </div>
    )
}
