import React from "react"
import useCookie from "./useCookie"
import useLocalStorage from "./useLocalStorage"

export default function useGetUserData(): object {
    return {
        l: useLocalStorage(),
        c: useCookie(),
    }
}
