import React, { Fragment, useEffect, useRef, useState } from "react"
import ErrorResult from "./ErrorResult"
import iconCoffee from "../icons/drink-icon.png"
import Page from "./Page"
import ApiCoffee from "./ApiCoffee"
import ResultPage from "./ResultPage"

export default function Coffee(props?: any) {
    const [errorState, setErrorState] = useState(false)

    const [apiRequestSent, setApiRequestSent] = useState(false)

    const [pageData, setPageData] = useState({
        qr: "",
        text: "",
        title: "",
        icon: "",
        code: "",
    })

    const objectContainsAll = (
        object: object,
        fields: Array<string>
    ): boolean => {
        fields.forEach((value) => {
            // if (!Object.hasOwn(object, value)) {
            if (!object.hasOwnProperty(value)) {
                return false
            }
        })
        return true
    }

    const handleResponse = (response: any) => {
        console.log("Handling response")
        if (errorState) {
            return
        }
        // if (!Object.hasOwn(response, "success") || response.success !== true) {
        if (!response.hasOwnProperty("success") || response.success !== true) {
            setErrorState(true)
            return
        }
        console.log(response)

        // Option 1: All things are valid and codeClaimed = false
        if (
            objectContainsAll(response, [
                "codeClaimed",
                "uniqueCode",
                "uniqueUrl",
                "surveyIdValid",
                "qrCode",
            ]) &&
            !response.codeClaimed &&
            response.surveyIdValid
        ) {
            // Update page data etc
            // @todo: use a single state object
            // setPageTitle({ text: "Thank You!", animate: false })
            // setPageIcon({
            //     src: imageLogoWhite,
            //     alt: "Cercle",
            //     width: 64,
            //     animate: true,
            // })
            // setPageText({ text: "Show me at the cafe" })
            setPageData({
                qr: response.qrCode,
                text: "Show me at the cafe",
                title: "Thank you!",
                icon: iconCoffee,
                code: response.uniqueCode,
            })
        }
    }

    const handleError = (error: any) => {
        console.log("Handling error")
        setErrorState(true)
    }

    useEffect(() => {
        setApiRequestSent(true)
    }, [])

    return (
        <Fragment>
            {!apiRequestSent && (
                <ApiCoffee
                    responseHandler={handleResponse}
                    errorHandler={handleError}
                />
            )}
            {errorState && <ErrorResult />}
            {!errorState && <ResultPage pageData={pageData} />}
        </Fragment>
    )
}
