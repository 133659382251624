import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import useApiRequest from "./useApiRequest"
import useGetUserData from "./useGetUserData"

export default function useApiPost(entry, responseHandler, errorHandler) {
    const params = useParams()

    // const paramId = Object.hasOwn(params, "*") ? params["*"] : ""
    const paramId = params.hasOwnProperty("*") ? params["*"] : ""
    const userData = useGetUserData()

    const isSurvey = String(entry).endsWith("survey")

    const sendData = {
        entry: entry,
        extra: userData,
    }

    if (isSurvey) {
        Object.assign(sendData, { surveyId: paramId })
    } else {
        Object.assign(sendData, { coffeeId: paramId })
    }

    const url = String(entry).endsWith("survey") ? "/survey" : "/coffee"

    useApiRequest(url, sendData, responseHandler, errorHandler)
}
