import React, { Fragment, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useNavigate, useParams } from "react-router-dom"
import useApiRequest from "../lib/api/useApiRequest"
import useCookie from "../lib/useCookie"
import useGetUserData from "../lib/useGetUserData"
import useLocalStorage from "../lib/useLocalStorage"
import ApiSurvey from "./ApiSurvey"
import ErrorResult from "./ErrorResult"
import Page from "./Page"
import { emptyPageData } from "./UserResult"

export default function Survey(props?: any) {
    const params = useParams()
    const [cookies, setCookie, removeCookie] = useCookies(["c_cu_s22"])
    const extraData = useGetUserData()
    const navigate = useNavigate()

    const [apiRequestSent, setApiRequestSent] = useState(false)
    const [errorState, setErrorState] = useState(false)

    // const surveyId = Object.hasOwn(params, "*") ? params["*"] : ""

    const handleResponse = (response: any) => {
        if (errorState) {
            return
        }

        // hasOwn() introduced to iOS15.4, Mar 2022. We'll still have users on lower iOS versions.
        // if (!Object.hasOwn(response, "success") || response.success != true) {
        if (!response.hasOwnProperty("success") || response.success != true) {
            setErrorState(true)
            return
        }

        // const localStringRes = Object.hasOwn(response, "localString")
        const localStringRes = response.hasOwnProperty("localString")
            ? response.localString
            : undefined

        // Store local string
        localStorage.setItem("c_cu_s22", localStringRes)

        let cookieDomain = process.env.REACT_APP_COOKIE_BASE_DOMAIN
        cookieDomain = "localhost:3000"
        if (!cookieDomain) {
            cookieDomain = "localhost"
        }
        const cookieExpires = new Date()
        cookieExpires.setDate(cookieExpires.getDate() + 365)
        setCookie("c_cu_s22", localStringRes, {
            path: "/",
            expires: cookieExpires,
            domain: cookieDomain,
        })

        // Redirect to unique URL
        // const uniqueUrl = Object.hasOwn(response, "uniqueUrl")
        const uniqueUrl = response.hasOwnProperty("uniqueUrl")
            ? response.uniqueUrl
            : undefined

        if (uniqueUrl) {
            navigate("/coffee/" + uniqueUrl)
        }
    }

    const handleError = (error: any) => {
        setErrorState(true)
    }

    useEffect(() => {
        setApiRequestSent(true)
    }, [])

    return (
        <Fragment>
            {!apiRequestSent && (
                <ApiSurvey
                    responseHandler={handleResponse}
                    errorHandler={handleError}
                />
            )}
            {errorState && <ErrorResult />}
        </Fragment>
    )
}
